import cryptoJs from 'crypto-js';

const replacements = {
	'/': '2pppp2',
	'+': '2ssss2',
	'=': '2eeee2',
};

export const encryptAES = (text: string, key: string): string => {
	if (!text || typeof text !== 'string') {
		throw new Error('Error: El texto a encriptar debe ser una cadena válida.');
	}
	if (!key || typeof key !== 'string') {
		throw new Error('Error: La clave debe ser una cadena válida.');
	}

	const hashedKey = cryptoJs.SHA256(key).toString().slice(0, 32);
	const parsedKey = cryptoJs.enc.Utf8.parse(hashedKey);

	const encrypted = cryptoJs.AES.encrypt(text, parsedKey, {
		mode: cryptoJs.mode.ECB,
	}).toString();

	return encrypted
		.split('/')
		.join(replacements['/'])
		.split('+')
		.join(replacements['+'])
		.split('=')
		.join(replacements['=']);
};

export const decryptAES = (encryptedBase64: string, key: string): string => {
	try {
		if (!encryptedBase64 || typeof encryptedBase64 !== 'string') {
			throw new Error('Error: El texto encriptado debe ser una cadena válida.');
		}
		if (!key || typeof key !== 'string') {
			throw new Error('Error: La clave debe ser una cadena válida.');
		}

		const hash = encryptedBase64
			.split(replacements['/'])
			.join('/')
			.split(replacements['+'])
			.join('+')
			.split(replacements['='])
			.join('=');

		const hashedKey = cryptoJs.SHA256(key).toString().slice(0, 32);
		const parsedKey = cryptoJs.enc.Utf8.parse(hashedKey);

		const decrypted = cryptoJs.AES.decrypt(hash, parsedKey, {
			mode: cryptoJs.mode.ECB,
		});
		const str = decrypted.toString(cryptoJs.enc.Utf8);

		if (!str) {
			throw new Error('Error: Clave incorrecta o datos corruptos.');
		}

		return str;
	} catch (error) {
		return '';
	}
};
